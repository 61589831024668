import React from "react";
import Navbar from "./Navbar";
import MainComponent from "./Card";
import MyChart from "./Chart";

const Dashboard = () => {
  return (
    <div className="flex">
      <Navbar />
      <div className="flex-grow bg-gray-100">
        <div>
          <h1 className="text-2xl font-semibold mb-2 text-center bg-blue-500 py-2 text-white top-0">
            Dashboard
          </h1>
        </div>
        <div className="p-8 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {/* Other dashboard-related content */}
          <MainComponent />
        </div>
        <div className="p-8">
          <MyChart />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
