import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import ImageViewModal from "./ImageViewModal";

const EnhancedTable = ({ rows }) => {
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("");
    const [filter, setFilter] = useState("");
    const [isImageViewOpen, setImageViewOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");

    const openImageView = (imageUrl) => {
        setSelectedImage(imageUrl);
        setImageViewOpen(true);
    };

    // Sorting functions
    const handleSort = (field) => {
        const isAsc = orderBy === field && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(field);
    };

    // Filter function
    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    // Apply filter
    const filteredRows = filter
        ? rows.filter((row) =>
              row.category.toLowerCase().includes(filter.toLowerCase())
          )
        : rows;

    // Sorting function
    const sortedRows = filteredRows.sort((a, b) => {
        const isAsc = order === "asc";
        return (isAsc ? 1 : -1) * (a[orderBy] < b[orderBy] ? -1 : 1);
    });

    const exportAsCSV = () => {
        const headers = [
            "Category",
            "Price",
            "Time Duration",
            "Net Amount",
            "Issue By",
            "Collect By",
            "Gate No",
            "In Time",
            "Check Out Time",
        ];

        const data = sortedRows.map((row) => {
            return [
                row.category,
                row.price,
                row.timeduration,
                row.netamount,
                row.issueby,
                row.collectby,
                row.gateno,
                moment(row.intime).format("YYYY-MM-DD HH:mm:ss"),
                moment(row.checkouttime).format("YYYY-MM-DD HH:mm:ss"),
            ];
        });

        const csvData = [headers, ...data];

        return csvData;
    };

    const exportAsPDF = () => {
        const doc = new jsPDF();

        // Create the data for the table (excluding the subtotal)
        const tableData = sortedRows.map((row) => [
            row.category,
            row.price,
            row.timeduration,
            row.netamount,
            row.issueby,
            row.collectby,
            row.gateno,
            moment(row.intime).format("YYYY-MM-DD HH:mm:ss"),
            moment(row.checkouttime).format("YYYY-MM-DD HH:mm:ss"),
        ]);

        // Calculate the subtotal
        const subtotal = sortedRows.reduce(
            (sum, row) => sum + row.netamount,
            0
        );

        // Add the table
        doc.autoTable({
            head: [
                [
                    "Category",
                    "Price",
                    "Time Duration",
                    "Net Amount",
                    "Issue By",
                    "Collect By",
                    "Gate No",
                    "In Time",
                    "Check Out Time",
                ],
            ],
            body: tableData,
        });

        // Manually add the subtotal
        const tableHeight = doc.previousAutoTable.finalY;
        doc.text("Subtotal", 10, tableHeight + 6);
        doc.text(`Rs.${subtotal.toFixed(2)}`, 170, tableHeight + 6);

        // Save the PDF
        doc.save("checkout_data.pdf");
    };

    return (
        <Paper>
            <div className="export-buttons">
                <CSVLink
                    data={exportAsCSV()}
                    filename={"checkout_data.csv"}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4 mt-6"
                >
                    Export as CSV
                </CSVLink>
                <button
                    onClick={exportAsPDF}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-6"
                >
                    Export as PDF
                </button>
            </div>
            <ImageViewModal
                isOpen={isImageViewOpen}
                onRequestClose={() => setImageViewOpen(false)}
                imageUrl={selectedImage}
            />

            <div className="mt-5">
                <TextField
                    variant="outlined"
                    label="Filter by Category"
                    value={filter}
                    onChange={handleFilterChange}
                />
            </div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {/* <TableCell>
                <TableSortLabel
                  active={orderBy === "cardid"}
                  direction={orderBy === "cardid" ? order : "asc"}
                  onClick={() => handleSort("cardid")}
                >
                  Card ID
                </TableSortLabel>
              </TableCell> */}
                            {/* Add similar headers for other fields */}
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === "category"}
                                    direction={
                                        orderBy === "category" ? order : "asc"
                                    }
                                    onClick={() => handleSort("category")}
                                >
                                    Category
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === "price"}
                                    direction={
                                        orderBy === "price" ? order : "asc"
                                    }
                                    onClick={() => handleSort("price")}
                                >
                                    Price
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === "netamount"}
                                    direction={
                                        orderBy === "netamount" ? order : "asc"
                                    }
                                    onClick={() => handleSort("netamount")}
                                >
                                    Net Amount
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === "issueby"}
                                    direction={
                                        orderBy === "issueby" ? order : "asc"
                                    }
                                    onClick={() => handleSort("issueby")}
                                >
                                    Issueby
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === "collectby"}
                                    direction={
                                        orderBy === "collectby" ? order : "asc"
                                    }
                                    onClick={() => handleSort("collectby")}
                                >
                                    CollectBy
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === "gateno"}
                                    direction={
                                        orderBy === "gateno" ? order : "asc"
                                    }
                                    onClick={() => handleSort("gateno")}
                                >
                                    GateNo
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === "intime"}
                                    direction={
                                        orderBy === "intime" ? order : "asc"
                                    }
                                    onClick={() => handleSort("intime")}
                                >
                                    InTime
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === "checkouttime"}
                                    direction={
                                        orderBy === "checkouttime"
                                            ? order
                                            : "asc"
                                    }
                                    onClick={() => handleSort("checkouttime")}
                                >
                                    Check OutTime
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === "timeduration"}
                                    direction={
                                        orderBy === "timeduration"
                                            ? order
                                            : "asc"
                                    }
                                    onClick={() => handleSort("timeduration")}
                                >
                                    TimeDuration
                                </TableSortLabel>
                            </TableCell>

                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === "imagepath"}
                                    direction={
                                        orderBy === "imagepath" ? order : "asc"
                                    }
                                    onClick={() => handleSort("imagepath")}
                                >
                                    Image
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedRows.map((row) => (
                            <TableRow key={row._id}>
                                {/* <TableCell>{row.cardid}</TableCell> */}
                                <TableCell>{row.category}</TableCell>
                                <TableCell>{row.price}</TableCell>
                                <TableCell>{row.netamount}</TableCell>
                                <TableCell>{row.issueby}</TableCell>
                                <TableCell>{row.collectby}</TableCell>
                                <TableCell>{row.gateno}</TableCell>
                                <TableCell>
                                    {moment(row.intime).format(
                                        "YYYY-MM-DD HH:mm:ss"
                                    )}
                                </TableCell>
                                <TableCell>
                                    {moment(row.checkouttime).format(
                                        "YYYY-MM-DD HH:mm:ss"
                                    )}
                                </TableCell>
                                <TableCell>{row.timeduration}</TableCell>
                                {/* <TableCell>
                  {
                    <img
                      src={`https://backvehicle.tfdatamaster.com/api/images/${row.imagepath}`}
                      alt="Checkout Image"
                    />
                  }
                </TableCell> */}
                                <TableCell>
                                    {
                                        <img
                                            src={`https://backvehicle.tfdatamaster.com/api/images/${row.imagepath}`}
                                            alt="Checkout Image"
                                            onClick={() =>
                                                openImageView(
                                                    `https://backvehicle.tfdatamaster.com/api/bestimages/${row.imagepath}`
                                                )
                                            }
                                        />
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default EnhancedTable;
