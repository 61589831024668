import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import { BsPlusCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { TEInput } from "tw-elements-react";
import DialogboxDelete from "./DialogboxDelete";

const Accounts = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [userData, setUserData] = useState([]); // State to hold user data
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [editData, setEditData] = useState({ id: "" });
    const history = useNavigate();

    const handleButtonClick = () => {
        history("/adduser");
    };

    const handleDelete = (item) => {
        setEditData({ _id: item._id });
        setDeleteDialogOpen(true);
    };

    useEffect(() => {
        // Function to fetch user data from your server

        const fetchUserData = async () => {
            try {
                console.log(
                    "Auth-Token:",
                    document.cookie.replace(
                        /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/,
                        "$1"
                    )
                );
                const response = await fetch(
                    "https://backvehicle.tfdatamaster.com/api/dashboard/allusers",
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "auth-token": document.cookie.replace(
                                /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/,
                                "$1"
                            ), // Get the token from the cookie
                        },
                    }
                );

                if (response.ok) {
                    const data = await response.json();
                    setUserData(data); // Set the user data in state
                } else {
                    console.error("Failed to fetch user data.");
                }
            } catch (error) {
                console.error("An error occurred:", error);
            }
        };

        fetchUserData(); // Call the function to fetch user data when the component mounts
    }, []);

    return (
        <div className="flex h-screen">
            <Navbar />
            <div className="flex-grow bg-gray-100">
                <h1 className="text-2xl font-semibold mb-2 text-center bg-blue-500 py-2 text-white">
                    Accounts
                </h1>

                <div className="p-8 flex justify-end">
                    <button
                        type="button"
                        className="inline-flex items-center rounded bg-blue-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                        onClick={handleButtonClick}
                    >
                        <BsPlusCircle className="mr-2" /> Add User
                    </button>
                </div>
                <div className="p-8">
                    <div className="flex items-center">
                        <TEInput
                            type="text"
                            id="searchInput"
                            label="Search Name"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                            <tr>
                                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    First Name
                                </th>
                                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Last Name
                                </th>
                                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Email Address
                                </th>
                                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Employee ID
                                </th>
                                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Gate NO
                                </th>
                                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {userData
                                .filter((item) =>
                                    searchTerm
                                        ? item.fname
                                              .toLowerCase()
                                              .includes(
                                                  searchTerm.toLowerCase()
                                              )
                                        : true
                                )
                                .map((item, index) => (
                                    <tr key={index}>
                                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                            {item.fname}
                                        </td>
                                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                            {item.lname}
                                        </td>
                                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                            {item.email}
                                        </td>
                                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                            {item.employeeid}
                                        </td>
                                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                            {item.gateno}
                                        </td>
                                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                            <button
                                                className="text-red-600 hover:underline"
                                                onClick={() =>
                                                    handleDelete(item)
                                                }
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
                <div>
                    <div>
                        {isDeleteDialogOpen && (
                            <DialogboxDelete
                                open={isDeleteDialogOpen}
                                onClose={() => setDeleteDialogOpen(false)}
                                onDelete={() => {
                                    setDeleteDialogOpen(false);
                                }}
                                _id={editData._id}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Accounts;
