import React from "react";
import Modal from "react-modal";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
  content: {
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    border: "none",
    background: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
};

const ImageViewModal = ({ isOpen, onRequestClose, imageUrl }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="High-Quality Image Modal"
      ariaHideApp={false}
    >
      <img
        src={imageUrl}
        alt="High-Quality Image"
        style={{ maxWidth: "100%", maxHeight: "100%" }}
        onClick={onRequestClose}
      />
    </Modal>
  );
};

export default ImageViewModal;
