import React, { useEffect, useState } from "react";
import axios from "axios";
import { TERipple } from "tw-elements-react";
import parkingslot from "../assets/parkingslot.jpg";
import motorcycleImage from "../assets/motorcycle.jpg"; // Assuming you have an image for the motorcycle card
import cars from "../assets/car.jpg";
import threewheel from "../assets/threewheel.jpg";
import bus from "../assets/bus.jpg";
import feet20 from "../assets/20feet.avif";
import feet40 from "../assets/40feet.avif";
import Cookies from "js-cookie";

const SlotCard = ({ image, title, availableSlots }) => {
    return (
        <div style={{ width: 250 }}>
            <div className="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 p-2">
                <a href="#!">
                    <img
                        className="rounded-t-lg w-50 h-32"
                        src={image}
                        alt=""
                    />
                </a>
                <div className="p-2">
                    <h1 className="mb-1 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
                        {availableSlots}
                    </h1>
                    <h1 className="mb-1 text-lg font-medium leading-tight text-neutral-800 dark:text-neutral-50">
                        {title}
                    </h1>
                </div>
            </div>
        </div>
    );
};

const MainComponent = () => {
    const [cardData, setCardData] = useState(null);
    console.log("CardData:", cardData);
    useEffect(() => {
        const fetchSlotData = async () => {
            try {
                const response = await fetch(
                    "https://backvehicle.tfdatamaster.com/api/slotdata/availableSlots",
                    {
                        method: "GET",
                        headers: {
                            "auth-token": Cookies.get("token"),
                        },
                    }
                );

                if (response.ok) {
                    const data = await response.json();
                    setCardData(data);
                } else {
                    console.error("Failed to fetch card data.");
                }
            } catch (error) {
                console.error(
                    "An error occurred while fetching card data:",
                    error
                );
            }
        };

        fetchSlotData();
    }, []);

    return (
        <>
            <SlotCard
                image={motorcycleImage}
                title="Motorcycle Slots"
                availableSlots={cardData ? cardData["Motorcycle"] : 0}
            />
            <SlotCard
                image={parkingslot}
                title="Parking Slots"
                availableSlots={cardData ? cardData["Total"] : 0}
            />
            <SlotCard
                image={cars}
                title="Car Slots"
                availableSlots={cardData ? cardData["Car/Van"] : 0}
            />
            <SlotCard
                image={threewheel}
                title="Threewheel Slots"
                availableSlots={cardData ? cardData["Three Wheel"] : 0}
            />
            <SlotCard
                image={bus}
                title="Bus Slots"
                availableSlots={cardData ? cardData["Bus"] : 0}
            />
            <SlotCard
                image={feet20}
                title="20 Feet Slots"
                availableSlots={cardData ? cardData["20 Feet"] : 0}
            />
            <SlotCard
                image={feet40}
                title="40 Feet Slots"
                availableSlots={cardData ? cardData["40 Feet"] : 0}
            />
        </>
    );
};

export default MainComponent;
