import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import IncomePieChart from "./IncomePieChart";
import Cookies from "js-cookie";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const Accounts = () => {
    const [chartData, setChartData] = useState(null);
    const [startDate, setStartDate] = useState("2023-10-10");
    const [endDate, setEndDate] = useState("2023-10-30");

    const downloadPdf = () => {
        const chartContainer = document.getElementById("incomePieChart");
        html2canvas(chartContainer).then((canvas) => {
            const chartImage = canvas.toDataURL("image/png");

            const pdf = new jsPDF();
            pdf.addImage(chartImage, "PNG", 10, 10, 200, 200); // Adjust the position and size as needed
            pdf.save("chart.pdf");
        });
    };

    useEffect(() => {
        // Fetch data from your backend using the provided URL and date range
        fetch(
            `https://backvehicle.tfdatamaster.com/api/slotdata/incomeByCategory?startDate=${startDate}&endDate=${endDate}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "auth-token": Cookies.get("token"),
                },
            }
        )
            .then((response) => response.json())
            .then((data) => {
                setChartData(data);
            })
            .catch((error) => console.error(error));
    }, [startDate, endDate]);

    return (
        <div className="flex">
            <Navbar />
            <div className="flex-grow bg-gray-100">
                <h1 className="text-2xl font-semibold mb-2 text-center bg-blue-500 py-2 text-white">
                    Analytics
                </h1>
                <div className="p-8">
                    <div className="flex justify-center mb-4">
                        <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                        <input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </div>
                    <div style={{ width: "500px", height: "500px" }}>
                        <IncomePieChart data={chartData} />
                    </div>
                    <button
                        onClick={downloadPdf}
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-6"
                    >
                        Download PDF
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Accounts;
