import React, { useEffect, useState } from "react";
import Chart from "chart.js/auto";

const IncomePieChart = ({ data }) => {
  const [chart, setChart] = useState(null);

  useEffect(() => {
    if (chart) {
      chart.destroy(); // Destroy the existing chart if it exists
    }

    if (data) {
      const labels = Object.keys(data);
      const values = Object.values(data);

      const ctx = document.getElementById("incomePieChart").getContext("2d");

      const newChart = new Chart(ctx, {
        type: "pie",
        data: {
          labels: labels,
          datasets: [
            {
              data: values,
              backgroundColor: [
                "#00CCCC",
                "#33FF99",
                "#CC66CC",
                "#FFFF00",
                // Add more colors as needed
              ],
            },
          ],
        },
      });

      setChart(newChart);
    }
  }, [data]);

  return (
    <div>
      <canvas id="incomePieChart"></canvas>
    </div>
  );
};

export default IncomePieChart;
