import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import EnhancedTable from "./Table"; // You can create a Table component to display the data
import Navbar from "../Navbar";
import Cookies from "js-cookie";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import ImageViewModal from "./ImageViewModal";

const VehicleList = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [vehicleData, setVehicleData] = useState([]);
    const [isImageViewOpen, setImageViewOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");

    useEffect(() => {
        if (startDate && endDate) {
            const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
            const formattedEndDate = moment(endDate).format("YYYY-MM-DD");

            // Make an API request to fetch data within the selected date range
            // Replace with your actual API endpoint and data fetching code
            fetch(
                `https://backvehicle.tfdatamaster.com/api/checkout/getCheckouts?startDate=${formattedStartDate}&endDate=${formattedEndDate}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "auth-token": Cookies.get("token"),
                    },
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    setVehicleData(data);
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    }, [startDate, endDate]);

    return (
        <div className="flex">
            <Navbar />
            <div className="flex-grow bg-gray-100">
                <h1 className="text-2xl font-semibold mb-2 text-center bg-blue-500 py-2 text-white">
                    Check Out Data
                </h1>
                <div className="p-4 flex flex-col lg:flex-row items-start">
                    <div className="date-picker">
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            isClearable
                            placeholderText="Start Date"
                        />
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            selectsEnd
                            endDate={endDate}
                            isClearable
                            placeholderText="End Date"
                        />
                    </div>
                </div>
                <EnhancedTable rows={vehicleData} />

                {/* Add ImageViewModal component for displaying high-quality image */}
                <ImageViewModal
                    isOpen={isImageViewOpen}
                    onRequestClose={() => setImageViewOpen(false)}
                    imageUrl={selectedImage}
                />
            </div>
        </div>
    );
};

export default VehicleList;
