import Navbar from "../Navbar";
import { useRef, useState } from "react";
import Webcam from "react-webcam";
import Example from "./CheckOut";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function UserPos() {
    const webRef = useRef(null);
    const [img, setImg] = useState(null);
    const [cardData, setCardData] = useState(null);
    const [cardId, setCardId] = useState(""); // New state variable for card ID
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const currentDateTime = new Date();
    const formattedDateTime = currentDateTime.toLocaleString();
    const navigate = useNavigate();
    const issueBy = Cookies.get("name") || "Issue by not found";
    const gateNo = Cookies.get("gateno") || "Gate no not found";

    const fetchCardData = async (cardId) => {
        try {
            const response = await fetch(
                `https://backvehicle.tfdatamaster.com/api/cards/getCard/${cardId}`,
                {
                    method: "GET",
                    headers: {
                        "auth-token": Cookies.get("token"),
                    },
                }
            );

            if (response.ok) {
                const cardData = await response.json();
                setCardData(cardData); // Update the state with fetched data
                console.log(cardData);
            } else {
                console.error("Failed to fetch card data.");
                setShowSuccessMessage(true);
                toast.error("Failed to get card data.");
            }
        } catch (error) {
            console.error("An error occurred while fetching card data:", error);
            setShowSuccessMessage(true);
            toast.error("An error getting card data.");
        }
    };

    const fetchCheckins = async (cardId) => {
        try {
            const response = await fetch(
                `https://backvehicle.tfdatamaster.com/api/checkin/getCheckins?cardid=${cardId}`,
                {
                    method: "GET",
                    headers: {
                        "auth-token": Cookies.get("token"),
                    },
                }
            );

            if (response.ok) {
                const checkinData = await response.json();
                if (checkinData.length > 0) {
                    // If there are check-in records for this card, redirect to the checkout page
                    navigate(`/checkout?cardid=${cardId}`);
                } else {
                    // If no check-in records are found, proceed to fetch card data
                    fetchCardData(cardId);
                }
            } else {
                console.error("Failed to fetch check-in data.");
            }
        } catch (error) {
            console.error(
                "An error occurred while fetching check-in data:",
                error
            );
        }
    };

    const captureImage = (e) => {
        e.preventDefault();
        const imageSrc = webRef.current.getScreenshot();
        setImg(imageSrc);
    };

    const cancelCapture = () => {
        setImg(null);
    };

    const handleCheckoutClick = (e) => {
        e.preventDefault(); // Prevent the default behavior of a button click
        Example();
    };

    const handleCardIdSubmit = async (e) => {
        e.preventDefault(); // Prevent form submission
        // const imageSrc = webRef.current.getScreenshot();
        const base64Image = img.split(",")[1];

        // Create an object with the image data
        const checkinData = {
            cardid: cardId,
            category: cardData?.data?.category || "Category not found",
            price: cardData?.data?.price || "Price not found",
            image: base64Image,
            issueby: issueBy,
            gateno: gateNo,
        };

        // Send the POST request to your backend API
        try {
            const response = await fetch(
                "https://backvehicle.tfdatamaster.com/api/checkin/addCheckin",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "auth-token": Cookies.get("token"),
                    },
                    body: JSON.stringify(checkinData),
                }
            );
            console.log(checkinData);

            if (response.ok) {
                // Handle success
                console.log("Checkin submitted successfully.");
                setShowSuccessMessage(true);
                toast.success("User CheckIn successfully.");
                setTimeout(() => {
                    window.location.reload();
                }, 800);
            } else {
                console.error("Failed to submit checkin.");
                setShowSuccessMessage(true);
                toast.error("Failed to submit checkin.");
            }
        } catch (error) {
            console.error("An error occurred while submitting checkin:", error);
            setShowSuccessMessage(true);
            toast.error("An error occurred while submitting checkin.");
        }
    };

    // New function to handle "Enter" key press
    const handleEnterPress = (e) => {
        if (e.key === "Enter") {
            // Fetch data when "Enter" key is pressed
            e.preventDefault();
            fetchCheckins(cardId);
        }
    };

    return (
        <div className="flex">
            <Navbar />
            <div className="flex-grow bg-gray-100">
                <h1 className="text-2xl font-semibold mb-2 text-center bg-blue-500 py-2 text-white">
                    Gate POS
                </h1>
                <div className="p-4 flex flex-col lg:flex-row items-start">
                    <div className="lg:w-1/3 mb-4">
                        <Webcam ref={webRef} className="rounded" />
                        <div className="mt-4">
                            <div className="flex flex-row">
                                <button
                                    className="bg-blue-500 text-white font-bold py-2 px-4 rounded flex-grow transition duration-300 ease-in-out transform hover:bg-blue-600 hover:scale-105"
                                    onClick={captureImage}
                                >
                                    Capture Image
                                </button>
                                {img && (
                                    <button
                                        className="bg-red-500 text-white font-bold py-2 px-4 rounded ml-4 transition duration-300 ease-in-out transform hover:bg-red-600 hover:scale-105"
                                        onClick={cancelCapture}
                                    >
                                        Cancel
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="lg-w-2/3 lg-ml-4">
                            {img && (
                                <div className="mt-4">
                                    <img
                                        src={img}
                                        alt="Captured"
                                        className="max-w-full max-h-full"
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div
                        className="lg:w-2/3 lg-ml-10"
                        style={{ marginLeft: "20px" }}
                    >
                        <form
                            className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2"
                            onSubmit={handleCardIdSubmit}
                        >
                            <div className="sm:col-span-2">
                                <label
                                    htmlFor="cardid"
                                    className="block text-sm font-semibold leading-6 text-gray-900"
                                >
                                    Card ID
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        name="cardid"
                                        id="cardid"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        value={cardId} // Bind input value to the state variable
                                        onChange={(e) =>
                                            setCardId(e.target.value)
                                        } // Update the state on input change
                                        onKeyPress={handleEnterPress} // Listen for "Enter" key press
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label
                                    htmlFor="category"
                                    className="block text-sm font-semibold leading-6 text-gray-900"
                                >
                                    Category
                                </label>
                                <div className="mt-2.5">
                                    <span className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        {cardData?.data?.category ||
                                            "Category not found"}
                                    </span>
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label
                                    htmlFor="issueBy"
                                    className="block text-sm font-semibold leading-6 text-gray-900"
                                >
                                    Issue By
                                </label>
                                <div className="mt-2.5">
                                    <span className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus-ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        {issueBy}
                                    </span>
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label
                                    htmlFor="gateNo"
                                    className="block text-sm font-semibold leading-6 text-gray-900"
                                >
                                    Gate No
                                </label>
                                <div className="mt-2.5">
                                    <span className="block w-full rounded-md border-0 px:3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus-ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        {gateNo}
                                    </span>
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label
                                    htmlFor="intime"
                                    className="block text-sm font-semibold leading-6 text-gray-900"
                                >
                                    In Time
                                </label>
                                <div className="mt-2.5">
                                    <span className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus-ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        {formattedDateTime}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <label
                                    htmlFor="priceperhour"
                                    className="block text-sm font-semibold leading-6 text-gray-900"
                                >
                                    Price Per Hour
                                </label>
                                <div className="mt-2.5">
                                    <span className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus-ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        {cardData?.data?.price ||
                                            "Price not found"}
                                    </span>
                                </div>
                            </div>
                            <div className="sm:col-span-2"></div>
                            <button
                                className="bg-blue-500 text-white font-bold py-2 px-4 rounded flex-grow transition duration-300 ease-in-out transform hover:bg-blue-600 hover:scale-105"
                                onClick={handleCardIdSubmit}
                            >
                                Submit
                            </button>
                            <div className="sm:col-span-2"></div>
                            <div>
                                <button
                                    className="bg-blue-500 text-white font-bold py-2 px-4 rounded flex-grow transition duration-300 ease-in-out transform hover:bg-blue-600 hover:scale-105"
                                    onClick={handleCheckoutClick}
                                >
                                    CHECKOUT
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={1500}
                hideProgressBar
            />
        </div>
    );
}
