import React, { useState, useEffect } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Cookies from "js-cookie";
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: "top",
        },
        title: {
            display: true,
            text: "Taken",
        },
    },
    elements: {
        line: {
            tension: 0.4,
        },
        point: {
            radius: (context) => {
                const value = context.dataset.data[context.dataIndex];
                const maxValue = Math.max(...context.dataset.data);
                const threshold = 0.9 * maxValue;
                return value >= threshold ? 8 : 4;
            },
            backgroundColor: (context) => {
                const value = context.dataset.data[context.dataIndex];
                const maxValue = Math.max(...context.dataset.data);
                const threshold = 0.9 * maxValue;
                return value >= threshold
                    ? "rgb(205,92,92)"
                    : "rgb(53, 162, 235)";
            },
        },
    },
};

export default function MyChart() {
    const [selectedTimePeriod, setSelectedTimePeriod] = useState("today");
    const [chartData, setChartData] = useState({
        labels: [], // Initialize with empty labels
        datasets: [
            {
                fill: true,
                label: "Place Taken",
                data: [], // Initialize with empty data
                borderColor: "rgb(53, 162, 235)", // Default color
                backgroundColor: "rgba(53, 162, 235, 0.5)", // Default color
                borderWidth: 2,
            },
        ],
    });

    const handleTimePeriodClick = (timePeriod) => {
        setSelectedTimePeriod(timePeriod);
        if (timePeriod === "week") {
            // Fetch week data when "Week" button is clicked
            fetchWeekData();
        } else if (timePeriod === "month") {
            // Fetch month data when "Month" button is clicked
            fetchMonthData();
        } else {
            // Fetch other data for different time periods
            fetchDataFromBackend(timePeriod);
        }
    };

    const fetchDataFromBackend = async () => {
        try {
            // Make an API request to the backend
            const response = await fetch(
                `https://backvehicle.tfdatamaster.com/api/slotdata/todayCheckout`,
                {
                    method: "GET",
                    headers: {
                        "auth-token": Cookies.get("token"),
                    },
                }
            );
            const data = await response.json();
            console.log("Chartdata:", data);

            let timeData = [];
            let netamountData = [];

            if (selectedTimePeriod === "today") {
                // Handle "today" data
                timeData = Object.keys(data);
                netamountData = Object.values(data);
            } else {
                // Handle "week" and "month" data
                timeData = data.map((item) => item.time);
                netamountData = data.map((item) => item.totalNetAmount);
            }

            setChartData({
                labels: timeData,
                datasets: [
                    {
                        ...chartData.datasets[0],
                        data: netamountData,
                    },
                ],
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchWeekData = async () => {
        try {
            const response = await fetch(
                "https://backvehicle.tfdatamaster.com/api/slotdata/last7DaysCheckout",
                {
                    method: "GET",
                    headers: {
                        "auth-token": Cookies.get("token"),
                    },
                }
            );
            const data = await response.json();
            console.log("Week Data:", data);

            const dateData = data.map((item) => item.date);
            const netamountData = data.map((item) => item.totalNetAmount);

            setChartData({
                labels: dateData, // Use date as labels
                datasets: [
                    {
                        ...chartData.datasets[0],
                        data: netamountData,
                    },
                ],
            });
        } catch (error) {
            console.error("Error fetching week data:", error);
        }
    };

    const fetchMonthData = async () => {
        try {
            const response = await fetch(
                "https://backvehicle.tfdatamaster.com/api/slotdata/last30DaysCheckout",
                {
                    method: "GET",
                    headers: {
                        "auth-token": Cookies.get("token"),
                    },
                }
            );
            const data = await response.json();
            console.log("Month Data:", data);

            const dateData = data.map((item) => item.date);
            const netamountData = data.map((item) => item.totalNetAmount);

            setChartData({
                labels: dateData, // Use date as labels
                datasets: [
                    {
                        ...chartData.datasets[0],
                        data: netamountData,
                    },
                ],
            });
        } catch (error) {
            console.error("Error fetching month data:", error);
        }
    };

    useEffect(() => {
        // Fetch initial data when the component mounts
        fetchDataFromBackend(selectedTimePeriod);
    }, [selectedTimePeriod]);

    return (
        <div>
            <div className="flex space-x-4 mb-4">
                <button
                    onClick={() => handleTimePeriodClick("today")}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                >
                    Today
                </button>
                <button
                    onClick={() => handleTimePeriodClick("week")}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                >
                    Week
                </button>
                <button
                    onClick={() => handleTimePeriodClick("month")}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                >
                    Month
                </button>
                {/* <button
          onClick={() => handleTimePeriodClick("year")}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
        >
          Year
        </button> */}
            </div>
            <Line options={options} data={chartData} />
        </div>
    );
}
