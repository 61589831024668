import React, { useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Cookies from "js-cookie";
export default function Edit({ _id, id, category, open, onClose, onSubmit }) {
    const cancelButtonRef = useRef(null);
    const [editedId, setEditedId] = useState(id);
    const [editedCategory, setEditedCategory] = useState(category);
    console.log(_id);

    const handleSave = async () => {
        const data = {
            cardid: editedId,
            category: editedCategory,
        };
        console.log(data);
        try {
            const response = await fetch(
                `https://backvehicle.tfdatamaster.com/api/cards/updateCard/${_id}`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "auth-token": Cookies.get("token"), // Include the auth-token from the cookie
                    },
                    body: JSON.stringify(data),
                }
            );

            if (response.ok) {
                // Successful update
                console.log("Card updated successfully.");
                window.location.reload();
                // Optionally, you can handle the response or perform additional actions here
            } else {
                console.error("Failed to update the card.");
            }
        } catch (error) {
            console.error("An error occurred while updating the card:", error);
        }

        onClose();
    };

    return (
        <Transition.Root show={open} as={React.Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 overflow-y-auto"
                onClose={onClose}
                initialFocus={cancelButtonRef}
            >
                <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>

                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <ExclamationTriangleIcon
                                            className="h-6 w-6 text-red-600"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg leading-6 font-medium text-gray-900"
                                        >
                                            Edit Category
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <div className="mb-4">
                                                <label
                                                    htmlFor="editedId"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    ID
                                                </label>
                                                <input
                                                    type="text"
                                                    name="editedId"
                                                    id="editedId"
                                                    className="mt-1 p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                    value={editedId}
                                                    onChange={(e) =>
                                                        setEditedId(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div>
                                                <label
                                                    htmlFor="editedCategory"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Category
                                                </label>
                                                <select
                                                    name="editedCategory"
                                                    id="editedCategory"
                                                    className="mt-1 p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                    value={editedCategory}
                                                    onChange={(e) =>
                                                        setEditedCategory(
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    <option value="Motorcycle">
                                                        Motorcycle
                                                    </option>
                                                    <option value="Three Wheel">
                                                        Three Wheel
                                                    </option>
                                                    <option value="Car/Van">
                                                        Car/Van
                                                    </option>
                                                    <option value="Bus">
                                                        Bus
                                                    </option>
                                                    <option value="20 Feet">
                                                        20 Feet
                                                    </option>
                                                    <option value="40 Feet">
                                                        40 Feet
                                                    </option>
                                                    {/* <option value="VIP">VIP</option> */}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="ml-3 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={handleSave}
                                >
                                    Submit
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                    onClick={onClose}
                                    ref={cancelButtonRef}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
