import React, { useState } from "react";
import { TEInput, TERipple } from "tw-elements-react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Userlogin() {
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [email, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleLogin = async () => {
        try {
            // Send a POST request to your backend API for login
            const response = await fetch(
                "https://backvehicle.tfdatamaster.com/api/users/login",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        email,
                        password,
                    }),
                }
            );

            if (response.ok) {
                const data = await response.json();
                Cookies.set("token", data.token, { expires: 1 });
                Cookies.set("email", data.email, { expires: 1 });
                Cookies.set("name", data.fname + " " + data.lname, {
                    expires: 1,
                });
                Cookies.set("gateno", data.gateno, { expires: 1 });
                Cookies.set("user", data.user, { expires: 1 });

                // Successful login, redirect to "/dashboard"
                navigate("/dashboard");
            } else {
                // Handle login failure, e.g., show an error message
                console.error("Login failed.");
                setShowSuccessMessage(true);
                toast.error("Login Failed.");
            }
        } catch (error) {
            console.error("An error occurred:", error);
            setShowSuccessMessage(true);
            toast.error("Somthing whent wrong.");
        }
    };

    return (
        <section className="h-screen">
            <div className="h-full">
                <div className="flex h-full flex-wrap items-center justify-center lg:justify-between">
                    <div className="shrink-1 mb-12 grow-0 basis-auto md:mb-0 md:w-9/12 md:shrink-0 lg:w-6/12 xl:w-6/12">
                        <img
                            src="https://media.istockphoto.com/id/1331615197/vector/tiny-people-park-%D1%81ar-in-parking-area-parking-lot-public-car-park-in-big-city-urban-transport.jpg?s=612x612&w=0&k=20&c=Sopq0ld274NZcDG_98VKBMAsY-yn3HKetwykiCDEh9A="
                            className="w-full"
                            alt="Sample image"
                        />
                    </div>

                    <div className="mb-12 md:mb-0 md:w-8/12 lg:w-5/12 xl:w-5/12">
                        <form>
                            <div className="my-4 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300">
                                <p className="mx-4 mb-0 text-center font-semibold dark:text-white">
                                    Login
                                </p>
                            </div>

                            <div className={`mb-6 ${email ? "has-value" : ""}`}>
                                {/* Email input */}
                                <TEInput
                                    type="email"
                                    label="Email address"
                                    size="lg"
                                    className={`mb-2 ${email ? "pt-5" : ""}`}
                                    value={email}
                                    onChange={handleUsernameChange}
                                />
                            </div>

                            <div
                                className={`mb-6 ${
                                    password ? "has-value" : ""
                                }`}
                            >
                                {/* Password input */}
                                <TEInput
                                    type="password"
                                    label="Password"
                                    className={`mb-2 ${password ? "pt-5" : ""}`}
                                    size="lg"
                                    value={password}
                                    onChange={handlePasswordChange}
                                />
                            </div>
                            {/* <!-- Login button --> */}
                            <div className="text-center lg:text-left">
                                <TERipple rippleColor="light">
                                    <button
                                        onClick={handleLogin}
                                        type="button"
                                        className="inline-block rounded bg-primary px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                                    >
                                        Login
                                    </button>
                                </TERipple>
                            </div>
                        </form>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={1500}
                    hideProgressBar
                />
            </div>
        </section>
    );
}
