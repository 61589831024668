import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import EnhancedTable from "./Table"; // You can create a Table component to display the data
import Navbar from "../Navbar";
import Cookies from "js-cookie";

const InparkVehicleList = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [vehicleData, setVehicleData] = useState([]);

    useEffect(() => {
        // Make an API request to fetch data within the selected date range
        // Replace with your actual API endpoint and data fetching code
        fetch(
            `https://backvehicle.tfdatamaster.com/api/checkin/getAllCheckins`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "auth-token": Cookies.get("token"),
                },
            }
        )
            .then((response) => response.json())
            .then((data) => {
                setVehicleData(data);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    });

    return (
        <div className="flex">
            <Navbar />
            <div className="flex-grow bg-gray-100">
                <h1 className="text-2xl font-semibold mb-2 text-center bg-blue-500 py-2 text-white">
                    Check Out Data
                </h1>
                <div className="p-4 flex flex-col lg:flex-row items-start"></div>
                <EnhancedTable rows={vehicleData} />
            </div>
        </div>
    );
};

export default InparkVehicleList;
