import { useState } from "react";
import {
  BsFillArrowLeftCircleFill,
  BsFillDashCircleFill,
  BsChatDotsFill,
  BsPersonFill,
  BsCalendarFill,
  BsSearch,
  BsBarChartFill,
  BsFolderFill,
  BsGearFill,
} from "react-icons/bs";
import imageSrc from "../assets/arrow.png";
import parkingicon from "../assets/parkingicon.png";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
const Navbar = ({ userEmail }) => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const Menus = [
    { title: "Dashboard", icon: BsFillDashCircleFill, route: "/dashboard" },
    { title: "Category", icon: BsChatDotsFill, route: "/category" },
    { title: "Accounts", icon: BsPersonFill, route: "/accounts" },
    { title: "POS", icon: BsCalendarFill, route: "/pos" },
    { title: "CheckOut", icon: BsSearch, route: "/checkoutfind" },
    { title: "Analytics", icon: BsBarChartFill, route: "/analytics" },
    { title: "Checkin", icon: BsFolderFill, route: "/parked" },
    { title: "Setting", icon: BsGearFill },
  ];

  // Filter the menu items based on the user's email
  const filteredMenus = Menus.filter((Menu) => {
    // Show "Category," "Accounts," "Analytics," and "Setting" only to admin

    if (Cookies.get("email") === "admin@sugathadasa.com") {
      return true;
    }
    // For other users, hide these menu items
    if (
      Menu.title === "Category" ||
      Menu.title === "Accounts" ||
      Menu.title === "Analytics" ||
      Menu.title === "Setting"
    ) {
      return false;
    }
    return true;
  });

  return (
    <div className="sticky top-0 z-50 bg-blue-600 h-screen p-5 pt-8 duration-300 flex flex-col">
      <div
        className={` ${
          open ? "w-72" : "w-20 "
        } bg-blue-600 h-full p-5  pt-8 relative duration-300`}
      >
        <img
          src={imageSrc}
          className={`absolute cursor-pointer -right-3 top-5 w-7 border-dark-purple
            border-2 rounded-full  ${!open && "rotate-180"}`}
          onClick={() => setOpen(!open)}
        />

        <div className="flex gap-x-4 items-center">
          <img
            src={parkingicon}
            className={`cursor-pointer duration-500 ${
              open && "rotate-[360deg]"
            }`}
            width={"80"}
            height={"80"}
          />
          <h1
            className={`text-white origin-left font-medium text-xl duration-200 ${
              !open && "scale-0"
            }`}
          >
            Sugathadasa Car Park
          </h1>
        </div>
        <ul className="pt-6">
          {filteredMenus.map((Menu, index) => (
            <li
              key={index}
              className={`flex  rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 
              ${Menu.gap ? "mt-9" : "mt-2"} ${
                index === 0 && "bg-light-white"
              } `}
              onClick={() => {
                if (Menu.route) {
                  navigate(Menu.route);
                }
              }}
            >
              {Menu.icon && <Menu.icon size={20} />}
              <span className={`${!open && "hidden"} origin-left duration-200`}>
                {Menu.title}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default Navbar;
