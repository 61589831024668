import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import ReactToPrint from "react-to-print";
import Cookies from "js-cookie";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment"; // Import the moment library

export default function CheckOut() {
    const [receivedAmount, setReceivedAmount] = useState("");
    const [checkoutData, setCheckoutData] = useState(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const componentRef = React.createRef();

    // Function to calculate the balance
    const calculateBalance = () => {
        if (receivedAmount === "") return;

        const received = parseFloat(receivedAmount);
        const totalCost = checkoutData["Net Amount"].toFixed(2); // Assuming a fixed total cost, you can replace this with your actual calculation

        if (!isNaN(received)) {
            return (received - totalCost).toFixed(2);
        }
    };

    const formatTime = (timeString) => {
        const options = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZone: "Asia/Colombo", // Specify the original time zone (Sri Lankan time)
        };
        return new Date(timeString).toLocaleString("en-US", options);
    };

    // Function to format duration in "7Hrs 28Mnts" format
    const formatDuration = (minutes) => {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = Math.floor(minutes % 60);
        return `${hours}Hrs ${remainingMinutes}Mnts`;
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const cardid = urlParams.get("cardid");

        // Fetch data with an authentication token provided as a cookie
        fetch(
            `https://backvehicle.tfdatamaster.com/api/checkin/getCheckins?cardid=${cardid}`,
            {
                method: "GET",
                headers: {
                    "auth-token": Cookies.get("token"),
                },
            }
        )
            .then((response) => response.json())
            .then((data) => {
                if (data && data.length > 0) {
                    // Customize the data format here
                    const formattedData = {
                        "Bill ID": data[0].billid,
                        Category: data[0].category,
                        "Charge PerHrs": data[0].price,
                        "Issue By": data[0].issueby,
                        "Gate No": data[0].gateno,
                        "In Time": formatTime(data[0].intime),
                        "Out Time": formatTime(data[0].checkouttime),
                        Duration: formatDuration(data[0].timeduration),
                        "Net Amount": data[0].netamount,
                        Image: data[0].imagepath,
                    };
                    setCheckoutData(formattedData); // Update state with the new data format
                    console.log(formattedData);
                    deleteCheckin(cardid, formattedData);
                } else {
                    // Handle the case where no data is found for the cardid
                }
            })
            .catch((error) => {
                console.error("Error fetching checkout data:", error);
                setShowSuccessMessage(true);
                toast.error("Error in Checkout data.");
            });
    }, []);

    // Function to delete checkin data
    const deleteCheckin = (cardid, formattedData) => {
        const syscollectby = Cookies.get("name");
        // Replace 'YOUR_AUTH_TOKEN' with the actual auth token
        const authToken = Cookies.get("token");
        console.log("Formatted: ", formattedData);
        fetch(
            `https://backvehicle.tfdatamaster.com/api/checkin/deleteCheckin/${cardid}`,
            {
                method: "DELETE",
                headers: {
                    "auth-token": authToken,
                },
            }
        )
            .then((response) => {
                if (response.status === 200) {
                    const checkoutData = {
                        cardid: cardid,
                        category: formattedData.Category,
                        price: formattedData["Charge PerHrs"],
                        imagepath: formattedData.Image,
                        netamount: formattedData["Net Amount"],
                        issueby: formattedData["Issue By"],
                        collectby: syscollectby,
                        gateno: formattedData["Gate No"],
                        intime: formattedData["In Time"],
                        checkouttime: formattedData["Out Time"],
                        timeduration: formattedData.Duration,
                        billid: formattedData["Bill ID"],
                    };

                    const modifiedRequestBody = {
                        ...checkoutData,
                        intime: moment(checkoutData.intime)
                            // .add(2, "hours")
                            // .add(30, "minutes")
                            .toISOString(),
                        checkouttime: moment(checkoutData.checkouttime)
                            // .add(2, "hours")
                            // .add(30, "minutes")
                            .toISOString(),
                    };
                    fetch(
                        "https://backvehicle.tfdatamaster.com/api/checkout/addcheckout",
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                "auth-token": authToken,
                            },
                            body: JSON.stringify(modifiedRequestBody),
                        }
                    )
                        .then((response) => {
                            if (response.status === 201) {
                                // Checkout data has been added successfully
                                setShowSuccessMessage(true);
                                toast.success(
                                    "Checkout and delete actions completed successfully."
                                );
                            } else {
                                setShowSuccessMessage(true);
                                toast.error("Error in adding checkout data.");
                            }
                        })
                        .catch((error) => {
                            console.error("Error adding checkout data:", error);
                            setShowSuccessMessage(true);
                            toast.error("Error in adding checkout data.");
                        });
                } else {
                    // setShowSuccessMessage(true);
                    // toast.error("Error in deleting checkin data.");
                }
            })
            .catch((error) => {
                console.error("Error deleting checkin data:", error);
                // setShowSuccessMessage(true);
                // toast.error("Error in deleting checkin data.");
            });
    };

    return (
        <div className="flex">
            <Navbar />
            <div className="flex-grow bg-gray-100">
                <h1 className="text-2xl font-semibold mb-2 text-center bg-blue-500 py-2 text-white">
                    POS Bill
                </h1>
                <div className="p-8">
                    <div className="flex">
                        <div className="w-1/2" style={{ marginRight: "20px" }}>
                            {/* Add the image outside the receipt */}
                            {checkoutData && checkoutData.Image && (
                                <img
                                    src={`https://backvehicle.tfdatamaster.com/api/bestimages/${checkoutData.Image}`}
                                    alt="Checkout Image"
                                />
                            )}
                        </div>
                        <div className="w-1/2">
                            <ReactToPrint
                                trigger={() => (
                                    <button className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                        Print
                                    </button>
                                )}
                                content={() => componentRef.current}
                            />
                            <div
                                className="bg-white p-4 rounded shadow-md"
                                ref={componentRef}
                            >
                                <h2 className="text-xl font-semibold text-gray-900 mb-4">
                                    Receipt
                                </h2>
                                {checkoutData ? (
                                    <div className="grid grid-cols-1 gap-2 text-sm">
                                        {Object.keys(checkoutData).map(
                                            (key) => (
                                                <div key={key} className="mb-2">
                                                    {key !== "Image" && (
                                                        <div>
                                                            <p className="font-medium text-gray-600">
                                                                {key}:
                                                            </p>
                                                            <p className="text-gray-900">
                                                                {
                                                                    checkoutData[
                                                                        key
                                                                    ]
                                                                }
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        )}
                                    </div>
                                ) : (
                                    <p>No data available for this cardid.</p>
                                )}
                                <div className="mt-4">
                                    <p className="text-xl font-semibold text-gray-900">
                                        Net Amount:{" "}
                                        {checkoutData
                                            ? `Rs.${parseFloat(
                                                  checkoutData["Net Amount"]
                                              ).toFixed(2)}`
                                            : "Loading..."}
                                    </p>
                                </div>
                                <div className="mt-6">
                                    <label
                                        htmlFor="receivedAmount"
                                        className="block text-sm font-medium text-gray-900"
                                    >
                                        Received Amount:
                                    </label>
                                    <input
                                        type="number"
                                        id="receivedAmount"
                                        name="receivedAmount"
                                        value={receivedAmount}
                                        onChange={(e) =>
                                            setReceivedAmount(e.target.value)
                                        }
                                        className="block w-full rounded-md border-gray-300 shadow-sm mt-1"
                                    />
                                </div>
                                <div className="mt-4">
                                    <p className="text-lg font-semibold text-gray-900">
                                        Balance: Rs.{calculateBalance()}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={1500}
                hideProgressBar
            />
        </div>
    );
}
