import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Userlogin from "./components/Login";
import Navbar from "./components/Navbar";
import Account from "./components/Users/Accounts";
import Dashboard from "./components/Dashboard";
import Category from "./components/Category/Category";
import Analytics from "./components/Analys/Analytics";
import AddUser from "./components/AddUser/AddAccount";
import UserPos from "./components/POS/UserPos";
import CheckOut from "./components/POS/CheckOut";
import VehicleList from "./components/CheckOutvehicle/VehicleList";
import InparkVehicleList from "./components/InParking/InparkVehicle";
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Userlogin />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/accounts" element={<Account />} />
          <Route path="/category" element={<Category />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/adduser" element={<AddUser />} />
          <Route path="/pos" element={<UserPos />} />
          <Route path="/checkout" element={<CheckOut />} />
          <Route path="/checkoutfind" element={<VehicleList />} />
          <Route path="/parked" element={<InparkVehicleList />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
