import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import { TEInput } from "tw-elements-react";
import Edit from "./DialogboxEdit";
import DialogboxDelete from "./DialogboxDelete";
import Cookies from "js-cookie";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Category = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [isEditDialogOpen, setEditDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [editData, setEditData] = useState({ id: "", category: "" });
    const [cardList, setCardList] = useState([]);
    const [newPrice, setNewPrice] = useState("");
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const options = [
        { value: "Motorcycle", label: "Motorcycle" },
        { value: "Three Wheel", label: "Three Wheel" },
        { value: "Car/Van", label: "Car/Van" },
        { value: "Bus", label: "Bus" },
        { value: "20 Feet", label: "20 Feet" },
        { value: "40 Feet", label: "40 Feet" },
        // { value: "VIP", label: "VIP" },
    ];

    const handleDropdownChange = (option) => {
        setSelectedOption(option);
        setDropdownOpen(false);
    };

    // Function to handle Edit button click
    const handleEdit = (item) => {
        setEditData({
            id: item.cardid,
            _id: item._id,
            category: item.category,
        });
        setEditDialogOpen(true);
    };

    const handleDelete = (item) => {
        setEditData({ _id: item._id });
        setDeleteDialogOpen(true);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleUpdatePrice = async () => {
        if (selectedOption) {
            const category = selectedOption.value; // Get the selected category

            // Prepare the request body
            const requestBody = {
                category,
                newPrice,
            };
            console.log(requestBody);

            try {
                const response = await fetch(
                    "https://backvehicle.tfdatamaster.com/api/cards/updateCategoryPrices",
                    {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            "auth-token": Cookies.get("token"), // Include the auth-token from the cookie
                        },
                        body: JSON.stringify(requestBody),
                    }
                );

                if (response.ok) {
                    // Request was successful, you can handle the response here
                    console.log("Price updated successfully.");
                    setShowSuccessMessage(true);
                    toast.success("User registered successfully.");
                    window.location.reload();
                } else {
                    console.error("Failed to update the price.");
                    setShowSuccessMessage(true);
                    toast.error("Failed to update the price.");
                }
            } catch (error) {
                console.error(
                    "An error occurred while updating the price:",
                    error
                );
                toast.error("Fail to Update price.");
                setShowSuccessMessage(true);
            }
        } else {
            console.error(
                "Please select a category before updating the price."
            );
            toast.error("Please select a category before updating the price.");
            setShowSuccessMessage(true);
        }
    };

    useEffect(() => {
        // Fetch card data from the backend when the component mounts
        async function fetchCardData() {
            try {
                const response = await fetch(
                    "https://backvehicle.tfdatamaster.com/api/cards/cards",
                    {
                        method: "GET",
                        headers: {
                            "auth-token": Cookies.get("token"), // Include the auth-token from the cookie
                        },
                    }
                );

                if (response.ok) {
                    const cardData = await response.json();
                    setCardList(cardData);
                    console.log(cardData);
                } else {
                    console.error("Failed to fetch card data.");
                    toast.error("Fail to get card data.");
                    setShowSuccessMessage(true);
                }
            } catch (error) {
                console.error(
                    "An error occurred while fetching card data:",
                    error
                );
                toast.error("Fail to get card data.");
                setShowSuccessMessage(true);
            }
        }

        fetchCardData();
    }, []);

    return (
        <div className="flex">
            <Navbar />
            <div className="flex-grow bg-gray-100">
                <h1 className="text-2xl font-semibold mb-2 text-center bg-blue-500 py-2 text-white">
                    Category
                </h1>
                <div className="flex items-center justify-between mb-4">
                    <div className="px-4 py-2 w-1/3 flex items-center">
                        <TEInput
                            type="text"
                            id="exampleFormControlInput1"
                            label="Charge per hour"
                            value={newPrice}
                            onChange={(e) => setNewPrice(e.target.value)} // Update newPrice with user input
                        />
                        <button
                            type="button"
                            className="ml-2 rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                            onClick={handleUpdatePrice}
                        >
                            Submit
                        </button>
                    </div>
                    <div className="relative inline-block text-right">
                        <button
                            type="button"
                            onClick={toggleDropdown}
                            className="bg-gray-300 text-gray-700 font-semibold px-4 py-2 rounded-full focus:outline-none"
                        >
                            {selectedOption
                                ? selectedOption.label
                                : "Select an option"}
                        </button>
                        <div
                            className={`${
                                isDropdownOpen ? "block" : "hidden"
                            } origin-top-left absolute right-0 mt-4 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5`}
                        >
                            <div
                                className="py-1"
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="options-menu"
                            >
                                {options.map((option) => (
                                    <button
                                        key={option.value}
                                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                        role="menuitem"
                                        onClick={() =>
                                            handleDropdownChange(option)
                                        }
                                    >
                                        {option.label}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-8">
                    <div className="flex items-center">
                        <TEInput
                            type="text"
                            id="searchInput"
                            label="Search ID"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                            <tr>
                                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    ID
                                </th>
                                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Category
                                </th>
                                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Price
                                </th>
                                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Date-Time
                                </th>
                                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Edit
                                </th>
                                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                    Delete
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {cardList
                                .filter((item) =>
                                    searchTerm
                                        ? item.cardid
                                              .toLowerCase()
                                              .includes(
                                                  searchTerm.toLowerCase()
                                              )
                                        : true
                                )
                                .map((item, index) => (
                                    <tr key={index}>
                                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                            {item.cardid}
                                        </td>
                                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                            {item.category}
                                        </td>
                                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                            {item.price}
                                        </td>
                                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                            {new Date(
                                                item.date
                                            ).toLocaleDateString()}{" "}
                                            {new Date(
                                                item.date
                                            ).toLocaleTimeString()}
                                        </td>
                                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                            <button
                                                className="text-blue-600 hover:underline"
                                                onClick={() => handleEdit(item)}
                                            >
                                                Edit
                                            </button>
                                        </td>
                                        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                            <button
                                                className="text-red-600 hover:underline"
                                                onClick={() =>
                                                    handleDelete(item)
                                                }
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {isEditDialogOpen && (
                <Edit
                    id={editData.id}
                    _id={editData._id}
                    category={editData.category}
                    open={isEditDialogOpen}
                    onClose={() => setEditDialogOpen(false)}
                />
            )}
            <div>
                <div>
                    {isDeleteDialogOpen && (
                        <DialogboxDelete
                            open={isDeleteDialogOpen}
                            onClose={() => setDeleteDialogOpen(false)}
                            onDelete={() => {
                                setDeleteDialogOpen(false);
                            }}
                            _id={editData._id}
                        />
                    )}
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={1500}
                hideProgressBar
            />
        </div>
    );
};

export default Category;
